import { Button } from "@/components/ui/button";
import { X } from "lucide-react";

export default function LeaveGame({
  leaveLobby,
  inLobby,
}: {
  leaveLobby: any;
  inLobby: any;
}) {
  const handleLeave = () => {
    if (window.confirm("Are you sure you want to leave the game?")) {
      leaveLobby(inLobby.id);
    }
  };
  return (
    <Button
      className="text-xs sm:text-sm ml-4 mt-4 p-3 fixed top-0 left-0"
      variant={"destructive"}
      onClick={handleLeave}
    >
      <X className="mr-0 sm:mr-2 h-4 w-4" />
      <span className="sm:block hidden">Leave game</span>
    </Button>
  );
}
