import { Card, CardContent } from "@/components/ui/card";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselPrevious,
  CarouselNext,
} from "@/components/ui/carousel";
import { Button } from "@/components/ui/button";
import { CheckCheck, Loader2, SendHorizonal, X } from "lucide-react";
import { useState, useEffect } from "react";
import { strengths } from "@/lib/data";
import { isMobile } from "react-device-detect";
import { IUser } from "@/lib/types";
import { Avatar, AvatarFallback } from "@/components/ui/avatar";

export default function SelectStrengths({
  selectStrengths,
  inLobby,
  user,
}: {
  selectStrengths: any;
  inLobby: any;
  user?: IUser;
}) {
  const [chosenStrengths, setChosenStrengths] = useState<string[]>([]);
  const [strengthList, setStrengthList] = useState<string[]>([]);

  const choseStrength = (strength: string) => {
    if (chosenStrengths.includes(strength)) {
      setChosenStrengths(chosenStrengths.filter((s) => s !== strength));
    } else if (chosenStrengths.length < 3) {
      setChosenStrengths([...chosenStrengths, strength]);
    }
  };

  useEffect(() => {
    setStrengthList(strengths.sort(() => 0.5 - Math.random()));
  }, []);

  const alreadySelected =
    inLobby.players.find(
      (player: any) => player.supertokensId === user?.supertokensId
    )?.strengths?.length === 3;

  return (
    <div className="h-full w-full p-4 flex justify-center items-center">
      {alreadySelected ? (
        <div className="flex flex-col">
          {inLobby.players.map((player: any) => (
            <div
              key={player.supertokensId}
              className="my-2 sm:my-4 flex items-center"
            >
              <Avatar className="w-12 h-12 sm:w-16 sm:h-16">
                <AvatarFallback
                  className={`${
                    player.strengths?.length === 3 ? "bg-lime-800" : ""
                  } font-extrabold tracking-tight text-base sm:text-xl`}
                >
                  {player.firstName[0]}
                  {player.lastName[0]}
                </AvatarFallback>
              </Avatar>
              <div className="flex items-center justify-center ml-4">
                {player.strengths?.length === 3 ? (
                  <>
                    <CheckCheck className="mr-2 w-4 h-4 text-lime-500" /> Ready
                  </>
                ) : (
                  <>
                    <Loader2 className="mr-2 w-4 h-4 animate-spin" />
                    Selecting...
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div>
          <div className="text-center font-extrabold tracking-tight text-base sm:text-2xl">
            Select 3 strengths that describe you best
          </div>
          <div className="flex justify-center mt-6 sm:mt-20">
            <div>
              <Carousel className="w-full max-w-xs sm:max-w-2xl">
                <CarouselContent className="-ml-2 sm:-ml-1 items-center">
                  {strengthList.map((strength) => (
                    <CarouselItem
                      key={strength}
                      className={`pl-1 basis-1/3 sm:basis-1/5 cursor-pointer items-center`}
                      onClick={() => choseStrength(strength)}
                    >
                      <div className="p-1">
                        <Card
                          className={`${
                            chosenStrengths.find((s) => s === strength)
                              ? "bg-slate-800"
                              : ""
                          }`}
                        >
                          <CardContent className="flex aspect-square items-center justify-center p-4 sm:p-6">
                            <span className="text-xs sm:text-base font-semibold">
                              {strength}
                            </span>
                          </CardContent>
                        </Card>
                      </div>
                    </CarouselItem>
                  ))}
                </CarouselContent>
                <CarouselPrevious />
                <CarouselNext />
              </Carousel>
            </div>
          </div>
          {!isMobile && (
            <div className="flex flex-wrap justify-center mt-6 sm:mt-20">
              {chosenStrengths.length === 0 && (
                <Button className="mx-1 sm:mx-2 text-xs sm:text-sm p-2 sm:p-4 mb-2 sm:mb-0 opacity-0">
                  <X className={"w-3 h-3 sm:w-4 sm:h-4 mr-1 sm:mr-2"} />
                  Hidden
                </Button>
              )}
              {chosenStrengths.map((strength) => (
                <Button
                  key={strength}
                  className="mx-1 sm:mx-2 text-xs sm:text-sm p-2 sm:p-4 mb-2 sm:mb-0"
                  onClick={() => choseStrength(strength)}
                >
                  <X className={"w-3 h-3 sm:w-4 sm:h-4 mr-1 sm:mr-2"} />
                  {strength}
                </Button>
              ))}
            </div>
          )}
          <div
            className={`flex justify-center mt-6 sm:mt-16 ${
              chosenStrengths.length === 3 ? "opacity-100" : "opacity-0"
            }`}
          >
            <Button
              variant={"outline"}
              className="w-40 bg-lime-800 text-base sm:text-lg"
              disabled={chosenStrengths.length < 3}
              onClick={() => selectStrengths(inLobby.id, chosenStrengths)}
            >
              Submit <SendHorizonal className="ml-2 w-4 h-4" />
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
