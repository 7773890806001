import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { toast } from "sonner";
import { signIn } from "supertokens-web-js/recipe/emailpassword";
import { useEffect, useState } from "react";
import { Loader2 } from "lucide-react";
import { Link, useNavigate } from "react-router-dom";
import Session from "supertokens-web-js/recipe/session";
import Header from "@/components/header";

export function LoginForm() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    async function doesSessionExist() {
      if (await Session.doesSessionExist()) {
        navigate("/", { replace: true });
      }
    }
    doesSessionExist();
    //eslint-disable-next-line
  }, []);

  const navigate = useNavigate();

  async function signInClicked() {
    setLoading(true);
    try {
      let response = await signIn({
        formFields: [
          {
            id: "email",
            value: email,
          },
          {
            id: "password",
            value: password,
          },
        ],
      });

      if (response.status === "FIELD_ERROR") {
        response.formFields.forEach((formField) => {
          if (formField.id === "email") {
            // Email validation failed (for example incorrect email syntax).
            toast.error(formField.error);
          }
        });
      } else if (response.status === "WRONG_CREDENTIALS_ERROR") {
        toast.error("Email password combination is incorrect.");
      } else if (response.status === "SIGN_IN_NOT_ALLOWED") {
        // the reason string is a user friendly message
        // about what went wrong. It can also contain a support code which users
        // can tell you so you know why their sign in was not allowed.
        toast.error(response.reason);
      } else {
        // sign in successful. The session tokens are automatically handled by
        // the frontend SDK.
        navigate("/", { replace: true });
      }
    } catch (err: any) {
      if (err.isSuperTokensGeneralError === true) {
        // this may be a custom error message sent from the API by you.
        toast.error(err.message);
      } else {
        toast.error("Oops! Something went wrong.");
      }
    }
    setLoading(false);
  }

  return (
    <Header>
      <div className="h-screen flex justify-center items-center">
        <Card className="mx-auto max-w-sm">
          <CardHeader>
            <CardTitle className="text-2xl">Login</CardTitle>
            <CardDescription>
              <span className="opacity-0">
                Enter your email below to login to your account
              </span>
            </CardDescription>
          </CardHeader>
          <CardContent>
            <div className="grid gap-4">
              <div className="grid gap-2">
                <Label htmlFor="email">Email</Label>
                <Input
                  id="email"
                  type="email"
                  required
                  onChange={(e) => setEmail(e.target.value)}
                  className="text-base"
                />
              </div>
              <div className="grid gap-2">
                <div className="flex items-center">
                  <Label htmlFor="password">Password</Label>
                  {/*<a href="#" className="ml-auto inline-block text-sm underline">
                  Forgot your password?
  </a>*/}
                </div>
                <Input
                  id="password"
                  type="password"
                  required
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      signInClicked();
                    }
                  }}
                  className="text-base"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <Button
                type="submit"
                className="w-full"
                disabled={loading}
                onClick={signInClicked}
              >
                {loading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
                Login
              </Button>
              {/*<Button variant="outline" className="w-full">
            Login with Google
  </Button>*/}
            </div>
            <div className="mt-4 text-center text-sm">
              Don&apos;t have an account?{" "}
              <Link to="/signup" className="underline cursor-pointer">
                Sign up
              </Link>
            </div>
          </CardContent>
        </Card>
      </div>
    </Header>
  );
}
