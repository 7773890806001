import { IUser } from "@/lib/types";
import { Loader2 } from "lucide-react";

export default function SelectDilemma({
  inLobby,
  user,
}: {
  inLobby: any;
  user?: IUser;
}) {
  const dilemmaHolder = inLobby.players[inLobby.currentDilemmaHolderIndex];
  const currentDilemmaHolder =
    dilemmaHolder?.supertokensId === user?.supertokensId;
  console.log(inLobby);
  return (
    <div className="h-full w-full flex justify-center items-center p-4">
      <div>
        {currentDilemmaHolder ? (
          <div>You are the dilemma holder. Select a dilemma</div>
        ) : (
          <div>
            <div className="flex justify-center">
              <Loader2 className="w-4 h-4 animate-spin" />
            </div>
            <div className="text-center mt-4">
              {dilemmaHolder?.firstName} {dilemmaHolder?.lastName} is selecting
              a dilemma
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
