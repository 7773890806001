import { IUser } from "@/lib/types";
import Header from "@/components/header";
import { useState, useEffect, useRef } from "react";
import { socket } from "@/socket";
import { toast } from "sonner";
import GameInProgress from "./game-in-progress";
import GameLobby from "./game-lobby";
import HomeLobby from "./home-lobby";

export default function Home({ user }: { user?: IUser }) {
  const [clientsCount, setClientsCount] = useState<null | number>(null);
  const [lobbies, setLobbies] = useState<null | any>(null);
  const hasJoinedLobby = useRef(false);

  useEffect(() => {
    socket.io.opts.query = { id: user?.supertokensId };
    socket.connect();
    return () => {
      socket.disconnect();
    };
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    function onConnect() {
      //setIsConnected(true);
    }

    function onDisconnect() {
      //setIsConnected(false);
    }

    function onClientsCount(count: number) {
      setClientsCount(count);
    }

    function onLobbies(lobbies: any) {
      setLobbies(lobbies);
    }

    function onError(error: any) {
      console.error(error);
      toast.error(error);
    }

    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    socket.on("clientsCount", onClientsCount);
    socket.on("lobbies", onLobbies);
    socket.on("error", onError);

    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
      socket.off("clientsCount", onClientsCount);
      socket.off("lobbies", onLobbies);
      socket.off("error", onError);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createLobby = () => {
    const lobbyName = `${user?.firstName} ${user?.lastName}`;
    socket.emit("createLobby", lobbyName);
  };

  const joinLobby = (lobbyId: string) => {
    socket.emit("joinLobby", lobbyId);
  };

  const leaveLobby = (lobbyId: string) => {
    socket.emit("leaveLobby", lobbyId);
  };

  const startMatch = (lobbyId: string) => {
    socket.emit("startMatch", lobbyId);
  };

  const selectStrengths = (lobbyId: string, strengths: string[]) => {
    socket.emit("selectStrengths", { lobbyId, strengths });
  };

  const selectDilemma = (lobbyId: string, dilemma: string) => {
    socket.emit("selectDilemma", { lobbyId, dilemma });
  };

  const selectSolution = (lobbyId: string, solution: string) => {
    socket.emit("selectSolution", { lobbyId, solution });
  };

  const chooseSolution = (lobbyId: string, solutionId: string) => {
    socket.emit("chooseSolution", { lobbyId, solutionId });
  };

  useEffect(() => {
    if (user?.gameSessions && lobbies && !hasJoinedLobby.current) {
      const playingSession = user.gameSessions.find(
        (session) => session.status === "playing"
      );
      if (playingSession) {
        socket.emit("joinLobby", playingSession.id);
        hasJoinedLobby.current = true;
      }
    }
  }, [user?.gameSessions, lobbies]);

  const inLobby: any = lobbies
    ? Object.values(lobbies).find((lobby: any) =>
        lobby.players.some(
          (player: any) => player.supertokensId === user?.supertokensId
        )
      )
    : null;

  return (
    <Header user={user}>
      <div
        className={`${
          inLobby ? (inLobby.status === "playing" ? "pt-0" : "pt-40") : "pt-64"
        } flex justify-center items-center max-w-full md:max-w-3xl lg:max-w-5xl xl:max-w-7xl mx-auto p-8`}
      >
        <div className="w-full">
          {inLobby ? (
            inLobby.status === "playing" ? (
              <GameInProgress
                user={user}
                inLobby={inLobby}
                leaveLobby={leaveLobby}
                selectStrengths={selectStrengths}
                selectDilemma={selectDilemma}
                selectSolution={selectSolution}
                chooseSolution={chooseSolution}
              />
            ) : (
              <GameLobby
                startMatch={startMatch}
                user={user}
                leaveLobby={leaveLobby}
                inLobby={inLobby}
              />
            )
          ) : (
            <HomeLobby
              createLobby={createLobby}
              joinLobby={joinLobby}
              lobbies={lobbies}
              clientsCount={clientsCount}
            />
          )}
        </div>
      </div>
    </Header>
  );
}
