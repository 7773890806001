import { LoginForm } from "./components/login";
import { SignUpForm } from "./components/signup";
import { Toaster } from "sonner";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import SuperTokens from "supertokens-web-js";
import Session from "supertokens-web-js/recipe/session";
import ThirdParty from "supertokens-web-js/recipe/thirdparty";
import EmailPassword from "supertokens-web-js/recipe/emailpassword";
import Home from "./components/home";
import RequireAuth from "./components/requireAuth";
import Profile from "./components/profile";
import { TooltipProvider } from "@/components/ui/tooltip";

SuperTokens.init({
  appInfo: {
    apiDomain: `${process.env.REACT_APP_API_URL}/`,
    apiBasePath: "/auth",
    appName: "Uppiness",
  },
  recipeList: [Session.init(), EmailPassword.init(), ThirdParty.init()],
});

function App() {
  return (
    <div>
      <Toaster
        toastOptions={{
          unstyled: false,
          classNames: {
            error: "bg-red-400",
            success: "text-green-400",
            warning: "text-yellow-400",
            info: "bg-blue-400",
          },
        }}
      />
      <TooltipProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<LoginForm />} />
            <Route path="/signup" element={<SignUpForm />} />
            <Route
              path="/profile"
              element={
                <RequireAuth>
                  <Profile />
                </RequireAuth>
              }
            />
            <Route
              path="/"
              element={
                <RequireAuth>
                  <Home />
                </RequireAuth>
              }
            />
          </Routes>
        </BrowserRouter>
      </TooltipProvider>
    </div>
  );
}

export default App;
