export const dilemmas = [
  "Every time there is a menial task at work, my manager tells me to do it.",
  "My team’s workload is unevenly distributed, so I work harder than others.",
  "I have a heavy workload. It makes me feel stressed and affects my private life.",
  "My compensation package does not match my contribution to the organization.",
  "All that managers care about is profits. They don't care about people or the quality of work.",
  "Our team is understaffed, and we cannot meet our goals.",
  "We have an employee in the organization that we wish to keep. We want him to stay, even if the rewards and possible promotions are very limited.",
  "In our staff, everyone is busy with their own personal tasks, and the group assignments 'fall through the cracks.'",
  "My team members are not helping each other, and that frustrates me.",
  "People in my workplace are busy assigning blame and justifications. There is no room to learn from mistakes.",
  "We have a colleague who gets mad and frowns at any management decision he doesn’t like.",
  "There is a lot of competitiveness in my team, which hurts the job and creates an unpleasant environment.",
  "My team members don’t strive for excellence, and I'm frustrated about it.",
  "There is a staff member in my team I can't stand, and he annoys me all day long.",
  "I want to take a professional course that will allow me to develop at work, and the organization refuses to pay for it.",
  "My job is dull and monotonous and I have no new challenges.",
  "My work is meaningless, I don't do anything of importance or value there.",
  "There are a lot of distractions around me and I can't concentrate on my work.",
  "I have no control over my work, I am like 'a puppet on a string' manipulated from above, without any control.",
  "I constantly worry whether I’m going to get fired, which paralyzes my abilities.",
  "My day is just one long meeting after another, with no time to think.",
  "I tend to postpone matters. It causes me stress and makes me react insensitively to my surroundings.",
  "My manager has no tolerance. When I make a mistake, even a small one, he yells at me and sometimes punishes me as well.",
  "I don’t have the tools to do my job properly, and nobody trains me.",
  "I never get appreciated when my work is done correctly.",
  "I want to move to another role, but my manager isn’t willing to back me up.",
  "Our manager is so busy with management meetings all day, that he is not available when needed.",
  "My manager wants to get involved in the smallest details; it offends me and slows the work down.",
  "Whenever I ask my manager a question he doesn't listen to me and immediately tells me what to do.",
  "I do not have an ounce of respect for my manager.",
  "I am bullied at work by a peer/manager.",
  "I am burned out because work doesn’t stop, people call me all day and night, I can’t get away from my work.",
  "I can’t afford to take time off as there is no one to pick up my work and it will just pile up when I get back.",
  "I have a work-life balance challenge, I feel I cannot be the parent I want to be and the employee I need to be.",
  "I am totally fatigued and I lack energy at work.",
];

export const strengths = [
  "Fairness",
  "Bravery",
  "Planning",
  "Organized",
  "Adaptability",
  "Foresight",
  "Self-Assurance",
  "Kindness",
  "Creativity",
  "Charisma",
  "Love of Learning",
  "Empathy and compassion",
  "Seeing the Big Picture",
  "Humor",
  "Wisdom",
  "Networking",
  "Positivity",
  "Persuasiveness",
  "Innovation",
  "Responsibility",
  "Inner Peace",
  "Zest",
  "Detail Oriented",
  "Self-Regulation",
  "Honesty",
  "Skills and Ability",
  "Social Intelligence",
  "Leadership",
  "Humility",
  "Communication",
  "Self-Discipline",
  "Achievement",
  "Prudence",
  "Determination",
  "Emotional Intelligence",
  "Improvisation",
  "Teamwork",
  "Assertive",
  "Intuition",
  "Logic",
  "Multi-Tasking",
  "Competitive",
  "Relationship Oriented",
  "Direct",
  "Flexible",
  "Decisive",
  "Passionate",
  "Resilient",
  "Loyal",
  "Analytical",
  "Plans for Future",
  "Independent",
  "Gratitude",
  "Task Oriented",
];

export const solutionsEmotional = [
  {
    title: "Permission to be human",
    text: "Don’t try to fight negative emotions when they are happening. Let them flow through you - acceptance allows you to better choose your response",
    extraInformation:
      "The first thing to become happier paradoxically is to accept negative emotions- they are part and parcel of being human/Tal ben Shahar",
    linkForPDF:
      "https://contextualscience.org/files/Art_and_Science_of_Thought_Suppression_compressed.pdf",
    TED: "https://www.youtube.com/watch?app=desktop&v=NDQ1Mi5I4rg",
  },
  {
    title: "Name-Tame-explain your emotions",
    text: "Denying or avoiding feelings doesn’t make them go away, nor does it lessen their impact on us. Noticing and naming emotions gives us the chance to take a step back and choose what to do with them.",
    extraInformation:
      "A new brain imaging study reveals why verbalizing our feelings makes our sadness, anger, and pain less intense",
    linkForPDF:
      "https://journals.sagepub.com/doi/abs/10.1111/j.1467-9280.2007.01916.x",
    TED: "https://www.ted.com/talks/arturs_miksons_the_benefits_of_expressing_your_emotions_constructively?language=en",
  },
  {
    title: "Sense the room",
    text: "Learn to observe what you and others are feeling and ask yourself why. Use this emotional knowledge to strengthen positive feelings.",
    extraInformation:
      "Research shows that EQ accounts for 85% of managerial success while IQ is only 15% / Daniel Goleman",
    linkForPDF:
      "https://cmapspublic2.ihmc.us/rid=1207228817342_1390637911_7833/Goleman-%20Socially%20Intelligent%20Leader.pdf",
    TED: "https://www.ted.com/talks/tasha_eurich_increase_your_self_awareness_with_one_simple_fix?language=en",
  },
  {
    title: "Empathy",
    text: "Make a genuine effort to understand what the other side might be feeling and express it to them. You don’t have to agree to it. Connect from that point before sharing your view",
    extraInformation:
      "Did you know? Research shows empathy levels are down 40% Gen Y's college students vs 30Y ago due to digital communication. Konrath, S. H., O'Brien, E. H., & Hsing, C. (2011).",
    linkForPDF: "https://journals.sagepub.com/doi/abs/10.1177/1088868310377395",
    TED: "https://www.ted.com/talks/joan_halifax_compassion_and_the_true_meaning_of_empathy?language=en",
  },
  {
    title: "Act vs React",
    text: "Once you are aware of your emotions, center yourself and share what you feel. Ensure the other hears what you have to say and is not caught up in the drama of how it is being said.",
    extraInformation:
      "Only 7% of your perceived message is driven by what you are saying, 93% is driven by HOW you are communicating",
    linkForPDF:
      "Mehrabian, A. (1971). Silent messages (Vol. 8, No. 152, p. 30). Belmont, CA: Wadsworth.",
    TED: "https://www.ted.com/talks/dan_ariely_how_to_change_your_behavior_for_the_better?language=en",
  },
  {
    title: "Dare to lead like a girl",
    text: "Connect to your softer feminine leadership traits like empathy, intuition, and team, and try using them alongside your masculine traits.",
    extraInformation:
      "A recent Harvard Business Review found that not only are women as effective as men, women actually score higher than men in 17/19 leadership traits including; purpose and goals, perseverance, and a variety of people skills. Zenger, J., & Folkman, J. (2019).",
    linkForPDF:
      "http://vjholcomb.com/wp-content/uploads/2020/03/Research_-Women-Score-Higher-Than-Men-in-Most-Leadership-Skills.pdf",
    TED: "https://www.ted.com/talks/dalia_feldheim_dare_to_lead_like_a_girl",
  },
  {
    title: "Forgiveness",
    text: "Forgive someone who wronged you at work- forgiving isn't forgetting but letting go makes room for fresh start",
    extraInformation:
      "Research proves the many benefits of forgiveness both psychologically and physically: Healthier relationships, Improved mental health, Less anxiety, stress, and hostility, lower blood pressure, A stronger immune system, improved heart health, Improved self-esteem",
    linkForPDF:
      "https://www.mayoclinic.org/healthy-lifestyle/adult-health/in-depth/forgiveness/art-20047692",
    TED: "https://www.ted.com/talks/sarah_montana_why_forgiveness_is_worth_it?language=en",
  },
  {
    title: "Diffuse with humor",
    text: "Diffuse tension using humor and laughter- proven to boost connection and open possibilities for resolution",
    extraInformation:
      "Humor has been proven as a key factor in peace-building and international mediation.",
    linkForPDF:
      "https://www.humorthatworks.com/benefits/30-benefits-of-humor-at-work/",
    TED: "https://www.youtube.com/watch?v=6iFCm5ZokBI",
  },
  {
    title: "Look for certainty",
    text: "In uncertain situations, search for certain things. Begin with what you clearly know, such as: where or why. Focus on what you can control",
    extraInformation:
      '"""God, grant me the serenity to accept the things I cannot change, courage to change the things I can, and wisdom to know the difference"". Reinhold Niebuhr.',
    linkForPDF: "https://hbr.org/2015/09/how-certainty-transforms-persuasion",
    TED: "https://www.ted.com/talks/mark_goh_uncertainty_is_the_only_certainty",
  },
  {
    title: "Expressing gratitude",
    text: "Think of someone who did something nice for you, helped or supported you during a time of need. Appreciate their contribution by writing them a thank you message.",
    extraInformation:
      '"When you appreciate the good, the good appreciates" Tal Ben Shahar',
    linkForPDF:
      "https://books.google.co.il/books?hl=en&lr=&id=tGCcH2l4jUUC&oi=fnd&pg=PP11&dq=THANKS+BY+ROBERT+EMMONS&ots=Z138VYqnxo&sig=GSHIH09wrk39r-GRveWLudjZ_kk&redir_esc=y#v=onepage&q=THANKS%20BY%20ROBERT%20EMMONS&f=false",
    TED: "https://www.youtube.com/watch?v=8lXYZ6s3Dfk&t=1s",
  },
  {
    title: "Love of self",
    text: "We can treat ourselves as we do others, with love and compassion. Accept and love yourself as you are, imperfect and incomplete.",
    extraInformation:
      "The most successful in any company were those who were self-full givers- able to give to others while taking care of themselves (Adam Grant / Give and Take / 2013)",
    linkForPDF:
      "https://static1.squarespace.com/static/5c243796e2ccd101ba5f08ce/t/5c67373f15fcc04208a0e9eb/1550268223292/STYLE--Give+and+Take.pdf",
    TED: "https://www.ted.com/talks/shanti_schiks_the_power_of_self_love",
  },
  {
    title: "How lucky am I",
    text: "In the midst of an adversary, try to step back and focus on what you are grateful for vs what you are missing.",
    extraInformation:
      "Research shows that participants that spent as little as 5 min writing 3 things they were grateful for that day we significantly happier, successful/ more likely to achieve more generous and physically healthier",
    linkForPDF:
      "https://books.google.co.il/books?hl=en&lr=&id=h-NhDwAAQBAJ&oi=fnd&pg=PA20&dq=Watkins,+P.+C.,+Emmons,+R.+A.,+%26+McCullough,+M.+E.+(2004).+Gratitude+and+subjective+well-being.&ots=NWOMRZlDjy&sig=acg2VtMHuNO7GP_yugKj2ZuUgEQ&redir_esc=y#v=onepage&q=Watkins%2C%20P.%20C.%2C%20Emmons%2C%20R.%20A.%2C%20%26%20McCullough%2C%20M.%20E.%20(2004).%20Gratitude%20and%20subjective%20well-being.&f=false",
    TED: "https://www.ted.com/talks/barry_schwartz_what_role_does_luck_play_in_your_life?language=en",
  },
  {
    title: "Ask for help & help others",
    text: "Ask a colleague for help and be willing to offer assistance to others.",
    extraInformation:
      "Spending on self boosts happiness only temporarily, whereas spending on others, boosts Happiness with a lasting impact",
    linkForPDF: "https://science.sciencemag.org/content/319/5870/1687.abstract",
    TED: "https://www.ted.com/talks/heidi_grant_how_to_ask_for_help_and_get_a_yes?language=en",
  },
  {
    title: "Fun time",
    text: "Find ways to incorporate playfulness and fun at your work.",
    extraInformation:
      "“A dose of humor releases the chemical serotonin in your brain, which improves focus, increases objectivity and improves overall brainpower.”",
    linkForPDF:
      "“A Dash of Humor Ups Performance and Creativity at Work”, Robyn McMaster. Brain Based Biz, September 2008.",
    TED: "https://www.ted.com/talks/laura_vanderkam_how_to_gain_control_of_your_free_time?language=en",
  },
  {
    title: "Celebrate successes",
    text: "Amplify positive emotions at work by celebrating successes on a daily basis.",
    extraInformation:
      "“In one study of more than 2,500 employees, 81 percent said they believe a fun working environment would make them more productive.”",
    linkForPDF:
      "https://journals.lww.com/acsm-healthfitness/Fulltext/2019/03000/CELEBRATE_SUCCESS_.13.aspx",
    TED: "https://www.youtube.com/watch?v=qxGapZbbI38",
  },
  {
    title: "Don’t get stressed by stress",
    text: "Accept stress as an inevitable part of working life. Give it space and try to use it as a driver for action.",
    extraInformation:
      "The research found that not stress itself, but rather our belief about stress was the driver of whether stress will be harmful to us or not",
    linkForPDF:
      "https://school.karpaty.info/data/docs/TED_pdf/TED_Kelly%20McGonigal_How%20to%20make%20stress%20your%20friend%20Script.pdf",
    TED: "https://www.ted.com/talks/daniel_levitin_how_to_stay_calm_when_you_know_you_ll_be_stressed?language=en",
  },
  {
    title: "PLAY",
    text: "When things are stuck, try engaging your team in play",
    extraInformation:
      "“We don’t stop playing because we grow old; we grow old because we stop playing.” -George Bernard Shaw",
    linkForPDF:
      "http://scholarpedia.org/article/Consequences_of_Play_Deprivation",
    TED: "https://www.ted.com/talks/stuart_brown_play_is_more_than_just_fun?language=en",
  },
  {
    title: "Write your heart out",
    text: "If emotions are intense, take time to journal about them, write an angry letter, if you still feel the same way after a week- go talk with your aggressor.",
    extraInformation:
      "Research shows writing or talking about negative experiences helps reduce stress as it enables analysis and growth. Lyubomirsky, S., Sousa, L., & Dickerhoof, R. (2006)",
    linkForPDF:
      "https://books.google.co.il/books?hl=en&lr=&id=3z5qDAAAQBAJ&oi=fnd&pg=PP1&dq=+JAMIE+PENNEBAKER+BOOK+HERE+ON+OPENING+UP&ots=wV6PDrfDZq&sig=Kl8SyqbhltRKZinjxaO6WBzOS5I&redir_esc=y#v=onepage&q=JAMIE%20PENNEBAKER%20BOOK%20HERE%20ON%20OPENING%20UP&f=false",
    TED: "https://www.youtube.com/watch?v=OfwDsx46Bhs",
  },
  {
    title: "Reduce Negativity",
    text: "Negative emotions have a greater effect on our mental state than positive or neutral emotions. Try keeping a positive ratio by reducing emotions such as shame, self-blame, complaint, etc and seek out positive emotions",
    extraInformation:
      "Positive emotions broaden one's attention and cognition, and therefore initiates upward spirals of emotional and physical self-development (Fredrickson, 2004)",
    linkForPDF:
      "https://royalsocietypublishing.org/doi/abs/10.1098/rstb.2004.1512",
    TED: "",
  },
];

export const solutionsSocial = [
  {
    title: "Coffee Chat",
    text: "Invite a colleague out for coffee - get to know each other as people and share your dilemma and seek their advice.",
    extraInformation:
      "Gallup’s research found that having best friends at work is a key driver of happiness at work- boosting engagement, resilience, and loyalty.",
    linkForPDF:
      "https://www.gallup.com/access/239210/gallup-q12-employee-engagement-survey.aspx",
    TED: "https://www.ted.com/talks/steven_johnson_where_good_ideas_come_from?referrer=playlist-how_coffee_can_bring_us_together",
  },
  {
    title: "Psychological Safety",
    text: "Encourage all team members to speak up freely and share new ideas without fear, to foster learning.",
    extraInformation:
      "Psychological safety was found to be the number one driver to create high-performing teams. Duhigg, C. (2016).",
    linkForPDF:
      "https://www.annualreviews.org/doi/abs/10.1146/annurev-orgpsych-031413-091305?journalCode=orgpsych",
    TED: "https://www.ted.com/talks/chris_strouthopoulos_psychological_safety_how_to_build_trust_and_create_open_successful_teams",
  },
  {
    title: "Approach with Grace",
    text: "Remind yourself that people have good intentions, try to see things from their perspective. Counteract angry feelings with understanding and compassion.",
    extraInformation:
      "Research by John Gottman found that positive conversations predicted over 90% of happy marriages.",
    linkForPDF: "https://psycnet.apa.org/record/1994-40800-001",
    TED: "https://www.ted.com/talks/dr_timothy_purnell_grace_and_courtesy_building_a_better_world",
  },
  {
    title: "Conflict is Connection",
    text: "Use your opponent’s energy to understand their deeper needs and find a win-win solution.",
    extraInformation:
      '"Don’t fight, or flight, but learn to dance with adversity" / Dalia Feldheim.',
    linkForPDF:
      "https://books.google.co.il/books?hl=en&lr=&id=S_ffhMnsufAC&oi=fnd&pg=PA11&dq=JOHN+GOTTMAN%27S+BOOKS+ON+MARRIAGE&ots=JBMwVBoaSp&sig=AESeUMcH-l2i7swQMJCZ2hegyUs&redir_esc=y#v=onepage&q=JOHN%20GOTTMAN'S%20BOOKS%20ON%20MARRIAGE&f=false",
    TED: "https://www.youtube.com/watch?v=sYflMk",
  },
  {
    title: "Find a Mentor",
    text: "Find and work with someone who has more experience or knowledge, who can help you develop.",
    extraInformation:
      "Mentorship has been proven to increase psychological safety and performance. Ramalho, J. (2014).",
    linkForPDF:
      "https://www.emerald.com/insight/content/doi/10.1108/ICT-11-2013-0078/full/html",
    TED: "https://www.youtube.com/watch?v=87qjIZRkkio",
  },
  {
    title: "Brainstorming",
    text: "Take advantage of the pool of wisdom in your team. Ask colleagues for advice on how to solve a difficult issue you are struggling with.",
    extraInformation:
      '"Creativity is not the domain of one single person. Through the free association of thoughts and brainstorming, an accidental suggestion can be the best solution" Joshua Fernandez.',
    linkForPDF:
      "Paulus, P. B., Nakui, T., & Putman, V. L. (2006). Group brainstorming and teamwork: Some rules for the road to innovation. Creativity and innovation in organizational teams, 69-86.",
    TED: "https://www.ted.com/talks/chic_thompson_life_is_a_brainstorm",
  },
  {
    title: "Peer Review",
    text: "Be brave, keep an open mind and ask for a colleague’s feedback. Let them know you have listened and thank them.",
    extraInformation:
      '"""Peer reviews offer the opportunity for growth for both the reviewer and the reviewee.""\n Iqbal, I. (2013).',
    linkForPDF:
      "https://www.tandfonline.com/doi/abs/10.1080/13562517.2013.764863",
    TED: "https://www.ted.com/talks/the_peer_reviews_the_peer_reviews_at_tedxtrondheim",
  },
  {
    title: "A Place of Gathering",
    text: "Create a comfortable and pleasant space at work, where you could meet and eat, drink, play and spend time together even virtually.",
    extraInformation:
      "“The power of gathering: it inspires us, delightfully, to be more hopeful, more joyful, more thoughtful: in a word, more alive.” Alice Waters.",
    linkForPDF:
      "Ahrens, N. (2015). The Eastside Blue Line Manifesto. https://issuu.com/clieuranc/docs/eastside_blueline_manifesto_spreads",
    TED: "https://www.ted.com/talks/priya_parker_3_steps_to_turn_everyday_get_togethers_into_transformative_gatherings?language=en",
  },
  {
    title: "Walk in My Shoes",
    text: "Team up with a colleague for one day, to boost empathy and understand their work and get to know them better.",
    extraInformation:
      "When we remind ourselves that we are all part of a common human network, we treat others as part of us (Oren Appel).",
    linkForPDF:
      "Thaler, L. K., & Koval, R. (2006). The power of nice: How to conquer the business world with kindness. Currency. https://books.google.co.il/books?hl=en&lr=&id=QlyeJIWQeHkC&oi=fnd&pg=PT4&dq=POWER+OF+NICE+BOOK+BY+LINDA+KAPLAN+THALER&ots=oYNBCITCW0&sig=7JIXLqyS5uV6I-CUYOxeRsYDLSs&redir_esc=y#v=onepage&q=POWER%20OF%20NICE%20BOOK%20BY%20LINDA%20KAPLAN%20THALER&f=false",
    TED: "https://www.youtube.com/watch?v=7mlV_bSBUi0",
  },
  {
    title: "Generosity to Others",
    text: "When we give- we get- set a regular time during the week to focus on paying attention to others and being of service to others.",
    extraInformation:
      "“You can always, always give something, even if its only kindness”- Anna Frank (Oren Appel).",
    linkForPDF:
      "https://static1.squarespace.com/static/5c243796e2ccd101ba5f08ce/t/5c67373f15fcc04208a0e9eb/1550268223292/STYLE--Give+and+Take.pdf",
    TED: "https://www.ted.com/talks/sasha_dichter_the_generosity_experiment",
  },
  {
    title: "Bonding Activity",
    text: "Plan a fun day out for your team. Visit somewhere, go to a restaurant, or go out in nature for an outdoor training activity to bring the group together.",
    extraInformation:
      "Research shows that for collective purposes, there is first a need to create ‘strong’ social capital bonds within the project team so that it becomes a cohesive social unit that will be able to effectively integrate knowledge. Newell, S., Tansley, C., & Huang, J. (2004).",
    linkForPDF:
      "Newell, S., Tansley, C., & Huang, J. (2004). Social capital and knowledge integration in an ERP project team: the importance of bridging and bonding. British journal of management, 15(S1), 43-57. https://onlinelibrary.wiley.com/doi/abs/10.1111/j.1467-8551.2004.00405.x",
    TED: "https://www.ted.com/talks/amy_edmondson_how_to_turn_a_group_of_strangers_into_a_team?language=en",
  },
  {
    title: "Team Board",
    text: "Prepare a large celebration board for your team- celebrate birthdays, events, team successes.",
    extraInformation:
      "“If you want to go fast, go alone. If you want to go far, go together.” African proverbs.",
    linkForPDF:
      "Hackman, J. R., & Katz, N. (2010). Group behavior and performance. https://psycnet.apa.org/record/2010-03506-032",
    TED: "https://www.ted.com/talks/helen_sanderson_self_managed_teams_an_insider_s_story",
  },
  {
    title: "Happy Hour for a Productivity Boost",
    text: "Set a time during the workweek, when the team can get together in a fun and informal way, perhaps with food and drink.",
    extraInformation:
      "Research proves the optimistic group outsold their more pessimistic counterparts by 19% in year one and 57% in year two. Shawn Achor.",
    linkForPDF:
      "Happy people are better workers - https://warwick.ac.uk/newsandevents/pressreleases/new_study_shows/",
    TED: "https://www.ted.com/talks/shawn_achor_the_happy_secret_to_better_work?language=en",
  },
  {
    title: "Relationship with Oneself",
    text: "You cannot pour from an empty jug- self-care is also knowing to set boundaries.",
    extraInformation:
      "The most successful in a company are the givers but also the less successful- the difference is that successful givers- give and get vs the less successful give at their own expense. Grant, A. M. (2013).",
    linkForPDF:
      "Grant, A. M. (2013). Give and take: A revolutionary approach to success. Penguin. https://www.goodreads.com/book/show/16158498-give-and-take",
    TED: "https://www.ted.com/talks/jen_oliver_how_to_love_yourself_to_the_core?language=en",
  },
  {
    title: "Work Life Balance",
    text: "You can have it all, but not all at once- book family/social time during the week to boost social support.",
    extraInformation:
      "Be there for the moments that count and make the moments you are there count /Dalia Feldheim.",
    linkForPDF:
      "Tim kasser - time affluence is a better predictor of well-being than money affluence https://www.jstor.org/stable/40294788",
    TED: "https://www.ted.com/talks/nigel_marsh_how_to_make_work_life_balance_work?language=en",
  },
  {
    title: "Humble Enquiry",
    text: "In every relationship- spend time asking questions- with curiosity and a real desire to better understand others' hidden needs.",
    extraInformation:
      '"My biggest advantage as a consultant is to act ignorant and ask questions. Not giving answers." Peter Drucker.',
    linkForPDF:
      "https://books.google.co.il/books?hl=en&lr=&id=XJjyDwAAQBAJ&oi=fnd&pg=PP1&dq=HUMBLE+INQUIRY+BY+EDGAR+SCHEIN&ots=LdEDuqyr7v&sig=zREvS1Vx9cvFvEaVekCL0JEvoiA&redir_esc=y#v=onepage&q=HUMBLE%20INQUIRY%20BY%20EDGAR%20SCHEIN&f=false",
    TED: "https://www.ted.com/talks/raymond_tang_be_humble_and_other_lessons_from_the_philosophy_of_water?language=en",
  },
  {
    title: "Appreciation",
    text: "Instead of looking for what is bad- focus on what you appreciate about the other.",
    extraInformation:
      '"When we appreciate the good in our life, the good in our life appreciates" Tal Ben-Shahar.',
    linkForPDF:
      "Orem, S. L., Binkert, J., & Clancy, A. L. (2007). Appreciative coaching: A positive process for change. John Wiley & Sons. https://books.google.co.il/books?hl=en&lr=&id=kNE4DwAAQBAJ&oi=fnd&pg=PR7&dq=APPRECIATIVE+COACHING+BY+SARA+ANN+CLANCY&ots=dzWt7dJ3cX&sig=wxXVca5tOiRwL4VKntJtKe1R1GE&redir_esc=y#v=onepage&q=APPRECIATIVE%20COACHING%20BY%20SARA%20ANN%20CLANCY&f=false",
    TED: "https://www.ted.com/talks/mike_robbins_the_power_of_appreciation",
  },
  {
    title: "Make Relationships a Priority",
    text: "Make it a habit to put your phone away when engaging in conversation- make eye contact and focus on what they are saying- being heard and seen is the key driver of a good relationship.",
    extraInformation:
      "Research by Daniel Kahneman found that parents who read email or spoke on the phone while being at the park with kids were significantly less happy and had weaker relationships.",
    linkForPDF:
      "Nickerson, C., Schwarz, N., Diener, E., & Kahneman, D. (2003). Zeroing in on the dark side of the American dream: A closer look at the negative consequences of the goal for financial success. Psychological science, 14(6), 531-536. https://www.researchgate.net/publication/8997464_Zeroing_in_on_the_Dark_Side_of_the_American_Dream_A_Closer_Look_at_the_Negative_Consequences_of_the_Goal_for_Financial_Success",
    TED: "https://www.ted.com/talks/mark_morin_building_sustainable_relationships_that_bring_brands_and_people_closer",
  },
  {
    title: "Be Yourself- Everyone Else Is Taken",
    text: "Have the courage to show up and be seen. Ask for what you need, share how you feel, be authentic and sincere.",
    extraInformation: '"Be yourself- everyone else is taken."',
    linkForPDF:
      "https://www.robertvanderwolk.nl/wp-content/uploads/Nathaniel-Branden-the-Six-Pillars-of-Self-esteem.pdf",
    TED: "https://www.ted.com/talks/live_to_be_yourself_jan_2019",
  },
];

export const solutionsMental = [
  {
    title: "Growth Mindset",
    text: "Every event is a chance to learn. Search for what you can learn and improve next time.",
    extraInformation: '"Learn to fail or fail to learn" /Tal Ben-Shahar.',
    linkForPDF:
      "https://books.google.co.il/books?hl=en&lr=&id=ckoKDQAAQBAJ&oi=fnd&pg=PT8&dq=Dweck,+C.+(2017).+Mindset-updated+edition:+Changing+the+way+you+think+to+fulfil+your+potential.+Hachette+UK.+&ots=au0QyoF97R&sig=ekK5DuzXt7J6RVQMmHIqKTG9W04&redir_esc=y#v=onepage&q=Dweck%2C%20C.%20(2017).%20Mindset-updated%20edition%3A%20Changing%20the%20way%20you%20think%20to%20fulfil%20your%20potential.%20Hachette%20UK.&f=false",
    TED: "https://www.ted.com/talks/carol_dweck_the_power_of_believing_that_you_can_improve?language=en",
  },
  {
    title: "Play to Your Strengths",
    text: "Know your strengths: your natural talents that allow you to perform at your best. Find new ways to apply these strengths every single day.",
    extraInformation:
      "Gallup’s research found those who focus on their strengths are twice as likely to succeed. Dweck, C. (2017).",
    linkForPDF:
      "https://langleygroupinstitute.com/wp-content/uploads/Linley-2008-Strengthspotting-chapter.pdf",
    TED: "https://www.ted.com/talks/nirupa_shankar_play_to_your_strengths",
  },
  {
    title: "Find Your WHY",
    text: "Search for meaning at work -ask yourself how your actions are connected to the greater good.",
    extraInformation:
      "Research shows that purpose-led individuals are 30% more productive. Tonin, M., & Vlassopoulos, M. (2015).",
    linkForPDF:
      "https://books.google.co.il/books?hl=en&lr=&id=iyw6ul1x_n8C&oi=fnd&pg=PR9&dq=SIMON+SINEK&ots=UNA7nWzBVW&sig=s-6wBYixIK8alS7vWo4pqZ2TmfU&redir_esc=y#v=onepage&q=SIMON%20SINEK&f=false",
    TED: "https://www.ted.com/talks/simon_sinek_how_great_leaders_inspire_action?language=en",
  },
  {
    title: "Learn From Success",
    text: "Analyze & celebrate your success- Determine which factors drove it and how can you expand into other fields.",
    extraInformation:
      "Research has found that soldiers who discussed both successes and failures learned at higher rates than soldiers who only analyzed failures. Ellis, S., & Davidi, I. (2005).",
    linkForPDF:
      "https://books.google.co.il/books?hl=en&lr=&id=Y7j_ecbTvl0C&oi=fnd&pg=PR5&dq=DAVID+COOPERRIDER+APPRECIATIVE+INQUIRY+BOOK&ots=p8kAsyziix&sig=B71uKLdjA1H_1FJoVZBtMJFLYEc&redir_esc=y#v=onepage&q=DAVID%20COOPERRIDER%20APPRECIATIVE%20INQUIRY%20BOOK&f=false",
    TED: "https://www.ted.com/talks/mark_suster_six_life_lessons_that_lead_to_success",
  },
  {
    title: "Challenge Yourself",
    text: "Magic happens when you step outside your comfort zone into the learning zone and face new challenges.",
    extraInformation:
      '"If it doesn’t challenge you it doesn’t change you" / anonymous.',
    linkForPDF:
      "https://www.jstor.org/stable/44735494?seq=1#metadata_info_tab_contents",
    TED: "https://www.ted.com/talks/tony_hsieh_how_to_challenge_yourself_out_of_your_comfort_zone",
  },
  {
    title: "What I love",
    text: "Identify what you love about your work. Find out how to do more of these things, and try to set goals in these areas.",
    extraInformation:
      '"If you do what you love, you will not work another day in your life" Marc Anthony.',
    linkForPDF: "https://psycnet.apa.org/record/2016-49324-001",
    TED: "https://www.ted.com/talks/brad_troeger_what_is_love?language=en",
  },
  {
    title: "Get in the Zone",
    text: "Get into a state of FLOW by finding the right balance between challenge and skill and immersing yourself in the 'here and now'.",
    extraInformation:
      '"The happiest people spend much time in a state of flow- so involved in an activity that nothing else matters. The experience itself is enjoyable" / Mihaly.',
    linkForPDF:
      "https://link.springer.com/chapter/10.1007/978-94-017-9088-8_15",
    TED: "https://www.ted.com/talks/eduardo_briceno_how_to_get_better_at_the_things_you_care_about?language=en",
  },
  {
    title: "It's Not About You",
    text: "If someone is upset, don't assume it is your fault. Separate yourself from the emotions, so you can respond rather than react.",
    extraInformation:
      "Happier people experience the same level of pain as unhappy people, they just don’t take it personally and know it shall pass. Seligman, M. E. (2006).",
    linkForPDF: "https://psycnet.apa.org/record/2002-18688-000",
    TED: "https://www.ted.com/talks/will_polston_it_s_not_about_you",
  },
  {
    title: "Set SMART Goals",
    text: "Chose goals that inspire you and utilize your strengths; make them specific, measurable, achievable realistic, and time-bound.",
    extraInformation:
      "Those who write down goals, commit to their peers and track them weekly are 42% more likely to achieve them. Barker, P. (2018)",
    linkForPDF:
      "https://www.sciencedirect.com/science/article/abs/pii/0030507369900300",
    TED: "https://www.ted.com/talks/john_doerr_why_the_secret_to_success_is_setting_the_right_goals?language=en",
  },
  {
    title: "Enjoy the Ride",
    text: "On your way towards the final goal, break it down to focus on the next step, and don’t forget to enjoy the journey.",
    extraInformation:
      '"Happiness is not about making it to the peak of the mountain, happiness is the experience of the climb towards the peak" / Tal Ben-Shahar.',
    linkForPDF: "https://journals.sagepub.com/doi/abs/10.1177/1088868314538549",
    TED: "https://www.youtube.com/watch?v=cwBm6H9QMAI",
  },
  {
    title: "Focus on the Glass Half Full",
    text: "Despite hardships, stay optimistic by focusing on the possibilities and opportunities the lie in the situation.",
    extraInformation:
      "Your brain at positive is 31% more productive than your brain at negative. Achor, S. (2010).",
    linkForPDF: "https://journals.sagepub.com/doi/abs/10.1177/1056492616688087",
    TED: "https://www.ted.com/talks/alison_ledgerwood_a_simple_trick_to_improve_positive_thinking/transcript?language=en",
  },
  {
    title: "Good Enough",
    text: "Aiming for perfection is exhausting and rarely ends with a sense of satisfaction. Focus on progress vs perfection.",
    extraInformation:
      "Satisfiers are happier because their standards are lower but more attainable. Optimizers strive to be the best but tend to be less happy because even if they achieve their goals they wonder what else they might have missed.",
    linkForPDF:
      "http://thebusinessleadership.academy/wp-content/uploads/2020/04/the-pursuit-of-perfect.pdf",
    TED: "https://www.ted.com/talks/damon_brown_why_you_should_strive_for_good_enough",
  },
  {
    title: "This Too Shall Pass",
    text: "When you come across a problem at work, remind yourself that this too shall pass; that is the nature of life. Remember that success also passes.",
    extraInformation:
      "Happier people experience the same level of pain as unhappy people, they just don’t take it personally and know it shall pass. Seligman, M. E. (2006).",
    linkForPDF:
      "Seligman, M. E. (2006). Learned optimism: How to change your mind and your life. Vintage.",
    TED: "",
  },
  {
    title: "Don’t Be Afraid to Fail",
    text: "If you come across a challenge that frightens you, it's not necessarily bad. Fear is created by new, unfamiliar challenges. It might represent excitement and a chance to grow.",
    extraInformation:
      "The best predictor of a leader’s success is how much hardship the leader endured to arrive at that position. McCall, M. W., Lombardo, M. M., & Morrison, A. M. (1988).",
    linkForPDF:
      "https://books.google.co.il/books?hl=en&lr=&id=VW4IpDx_oFAC&oi=fnd&pg=PR9&dq=McCall,+M.+W.,+Lombardo,+M.+M.,+%26+Morrison,+A.+M.+(1988).+Lessons+of+experience:+How+successful+executives+develop+on+the+job.+Simon+and+Schuster&ots=2iMoW70JC4&sig=Ds27MzWL626H_zDVcxEGW8NeR6s&redir_esc=y#v=onepage&q=McCall%2C%20M.%20W.%2C%20Lombardo%2C%20M.%20M.%2C%20%26%20Morrison%2C%20A.%20M.%20(1988).%20Lessons%20of%20experience%3A%20How%20successful%20executives%20develop%20on%20the%20job.%20Simon%20and%20Schuster&f=false",
    TED: "https://www.ted.com/talks/sandhya_shekhar_banishing_the_fear_of_failure",
  },
  {
    title: "Keep It Simple",
    text: "Try to take small simple steps. Making things too complex will slow you down.",
    extraInformation:
      "Did you know- multi-tasking (reading emails while doing other mental tasks) is equivalent to reducing your IQ by 10 points. Glen Wilson 2005.",
    linkForPDF: "https://journals.aom.org/doi/abs/10.5465/amr.2001.5393887",
    TED: "https://www.youtube.com/watch?v=sC5vyqQ_uB4",
  },
  {
    title: "Beginners Mind",
    text: "Live each day as if it were your first- ask questions, seek to understand, be curious about everything.",
    extraInformation:
      "Leaders who adopt a beginners’ mind- show higher levels of happiness, health, stronger relationships, improved memory, and creativity. Langer, E. J. (2000).",
    linkForPDF:
      "Langer, E. J. (2000). Mindful learning. Current directions in psychological science, 9(6), 220-223.",
    TED: "https://www.youtube.com/watch?v=A0yC45yFd5U",
  },
  {
    title: "Learn to Say NO",
    text: "Learn to say NO. As you make your TO DO list, it is as important to make your 'NOT TO DO' list.",
    extraInformation:
      "People who made a not-to-do list saved 90 min a day- this list covers things you have no control over, that don’t have to get done, or that can be delegated- so you can focus on the essential. Stack, L. (2012)",
    linkForPDF:
      "Stack, L. (2012). What to Do when There's Too Much to Do: Reduce Tasks, Increase Results, and Save 90 Minutes a Day. Berrett-Koehler Publishers.",
    TED: "https://www.ted.com/talks/william_clark_dare_to_say_no",
  },
  {
    title: "Visualize Your Desired Outcome",
    text: "When stressed, visualize yourself succeeding at the task. Start each morning with priming yourself- what does success today look like.",
    extraInformation:
      "Did you know? In 1954 Roger Bannister used visualization to run a mile in under 4 min despite doctors claiming it was not physically possible. Sergeant, S., & Mongrain, M. (2014).",
    linkForPDF:
      "https://journals.sagepub.com/doi/abs/10.1177/0146167299025002010",
    TED: "https://www.youtube.com/watch?v=zESeeaFDVSw",
  },
  {
    title: "Words Create Worlds",
    text: "Use positive reframing both towards oneself and to others to positively impact the outcome.",
    extraInformation:
      "Research shows that exposure to positive words increases positivity, exposure to righteous and religious words increases the chances of admitting wrongdoing.",
    linkForPDF:
      "https://books.google.co.il/books?hl=en&lr=&id=Kmz17VT2DskC&oi=fnd&pg=PR9&dq=Aitchison,+J.+(2012).+Words+in+the+mind:+An+introduction+to+the+mental+lexicon.+John+Wiley+%26+Sons.&ots=ljP6DH6lxS&sig=2U6UsyzTJbkZ2VCTRS1hj-LRwqI&redir_esc=y#v=onepage&q=Aitchison%2C%20J.%20(2012).%20Words%20in%20the%20mind%3A%20An%20introduction%20to%20the%20mental%20lexicon.%20John%20Wiley%20%26%20Sons.&f=false",
    TED: "https://www.ted.com/talks/sarah_millimen_your_words_create_worlds",
  },
];

export const solutionsPhysical = [
  {
    title: "Stress as Friend not Foe",
    text: "See stress as a source of energy, your accelerated heart rate and intensified breathing prepare you for action. Take advantage of it!",
    extraInformation:
      "Bankers during the 2008 crisis who were taught to reframe stress as a source of energy and focus were more productive. Crum, A. J., Jamieson, J. P., & Akinola, M. (2020)",
    linkForPDF:
      "Crum, A. J., Jamieson, J. P., & Akinola, M. (2020). Optimizing stress: An integrated intervention for regulating stress responses. Emotion, 20(1), 120.",
    TED: "https://www.ted.com/talks/kelly_mcgonigal_how_to_make_stress_your_friend?language=en",
  },
  {
    title: "Recharge",
    text: "From time to time stop and check your energy level. Recharge yourself by any of the five key energy boosters: breath, eat, touch, rest, and move.",
    extraInformation: '"You can\'t pour from an empty jug" Anonymous.',
    linkForPDF:
      "Schwartz, T., & McCarthy, C. (2007). Manage your energy, not your time. Harvard business review, 85(10), 63.",
    TED: "https://www.ted.com/talks/suhong_park_the_power_of_recharging",
  },
  {
    title: "Digital Detox",
    text: "Create a break between work and personal time. Avoid working evenings and weekends to stay fresh and productive.",
    extraInformation:
      "Digital detox one evening a week drove performance up 74%. Perlow, L. A. (2012).",
    linkForPDF:
      "Perlow, L. A. (2012). Breaking the Smartphone Addiction. Harvard Business School Working Knowledge: Research and ideas, retrieved May, 2, 2016.",
    TED: "https://www.ted.com/talks/josh_misner_what_i_learned_from_10_years_of_digital_detox",
  },
  {
    title: "Oxygen=Energy",
    text: "As simple as it may sound- when stressed - Take a slow deep breath to pump more oxygen to your brain and energy to your body.",
    extraInformation:
      "Deep breathing produces five times more energy vs the same process with low oxygen.",
    linkForPDF:
      "Weibel, E. R. (1984). The pathway for oxygen: structure and function in the mammalian respiratory system. Harvard University Press.  ALSO SEE JAMES NESTOR ON BREATH",
    TED: "https://www.youtube.com/watch?v=_QTJOAI0UoU",
  },
  {
    title: "Meditate",
    text: "Schedule a moment of silence during your daily race. Pay attention to your breath and emotions, proven to calm, boost mood, and focus.",
    extraInformation:
      "People who practice as little as five minutes of meditation a day for four weeks showed significantly higher focus and creativity. Levinson, D. B., Stoll, E. L., Kindy, S. D., Merry, H. L., & Davidson, R. J. (2014).",
    linkForPDF:
      "Levinson, D. B., Stoll, E. L., Kindy, S. D., Merry, H. L., & Davidson, R. J. (2014). A mind you can count on: validating breath counting as a behavioral measure of mindfulness. Frontiers in psychology, 5, 1202.  ALSO FULL CATASTROPHE LIVING BY KABAT ZINN",
    TED: "https://www.ted.com/talks/jessica_amos_a_quick_mindfulness_guided_meditation",
  },
  {
    title: "Food as Fuel",
    text: "Energize your body with the right fuel if you want to have more energy- Plan ahead.",
    extraInformation:
      "Good snacking (seven small portions of veggies, nuts, and fruit) has proven to boost happiness, engagement, memory power, and creativity.",
    linkForPDF:
      "Friedman, R. (2014). ’What you eat affects your productivity’. Harvard Bussiness.",
    TED: "https://www.ted.com/talks/mia_nacamulli_how_the_food_you_eat_affects_your_brain/transcript?language=en",
  },
  {
    title: "The Healing Power of Touch",
    text: "Enjoy the healing power of touch - get a hug, go for a massage, or caress your pet to reduce pain and anxiety.",
    extraInformation:
      "Research proves touch releases opioids that reduce pain, reduce anxiety, and strengthen the immune system and cognitive development. Field, T. (2014).",
    linkForPDF: "Field, T. (2014). Touch. MIT press.",
    TED: "https://www.youtube.com/watch?v=tafP2OgttCI",
  },
  {
    title: "Take a Rest",
    text: "Enjoy an enriching full night’s sleep to boost physical cognitive and emotional wellness.",
    extraInformation:
      "One night sleep loss is equivalent to a 10 point IQ drop. Mednick, S., Nakayama, K., & Stickgold, R. (2003).",
    linkForPDF:
      "Mednick, S., Nakayama, K., & Stickgold, R. (2003). Sleep-dependent learning: a nap is as good as a night. Nature neuroscience, 6(7), 697-698.",
    TED: "https://youtu.be/MklZJprP5F0",
  },
  {
    title: "Take a Day Off",
    text: "Once in a while treat yourself to a day off work, with no work-related activities.",
    extraInformation:
      "Multiple research has found that taking a proper vacation is associated with a healthier heart, helps regulate your mood, and boosts focus and productivity.",
    linkForPDF: "https://www.sangoma.com/articles/day-off/",
    TED: "https://www.ted.com/talks/stefan_sagmeister_the_power_of_time_off",
  },
  {
    title: "A Date with Yourself",
    text: "Make a commitment to leave the office early at least once a week. Do something that recharges your energy (a sport, hobby, studying, a massage, etc.).",
    extraInformation:
      '"The most important relationship is the one you have with yourself" / Diane Von Furstenberg',
    linkForPDF:
      "Drick, C. A. (2014). Nurturing Yourself to Enhance Your Practice. International Journal of Childbirth Education, 29(1).",
    TED: "https://www.youtube.com/watch?v=P3fIZuW9P_M",
  },
  {
    title: "Time In to Reflect",
    text: "Schedule moments in. Take time to reflect and consider your actions, and check if you are being true to your core values.",
    extraInformation: '"Leadership is first BEING than DOING" /Warren Bennis',
    linkForPDF: "Bennis, W. G. (2009). On becoming a leader. Basic Books.",
    TED: "https://www.ted.com/talks/david_peabody_the_art_of_reflection",
  },
  {
    title: "Exercise Together",
    text: "Set up a weekly sports activity. Invite friends from work to join you and exercise together to boost concentration and mood.",
    extraInformation:
      "Did you know? Exercise three times a week for 30 min has the same benefits on mood as taking an anti-depressant. Ratey, J. J. (2008).",
    linkForPDF:
      "Ratey, J. J. (2008). Spark: The revolutionary new science of exercise and the brain. Little, Brown Spark.",
    TED: "https://www.youtube.com/watch?v=BHY0FxzoKZE",
  },
  {
    title: "A Walking Meeting",
    text: "Hold some of your meetings while walking together outdoors. Walking stimulates the blood and strengthens the body, contributing to concentration and meeting effectiveness.",
    extraInformation:
      "If walking meetings were a key productivity strategy for Aristotle, Freud, Jobs, and Harry Truman- why not give it a go? Ahtinen, A., Andrejeff, E., Harris, C., & Väänänen, K. (2017)",
    linkForPDF:
      "Ahtinen, A., Andrejeff, E., Harris, C., & Väänänen, K. (2017, September). Let's walk at work: persuasion through the brainwork walking meeting app. In Proceedings of the 21st International Academic Mindtrek Conference (pp. 73-82).",
    TED: "https://www.ted.com/talks/nilofer_merchant_got_a_meeting_take_a_walk?language=en",
  },
  {
    title: "Silence Notifications",
    text: "Turn off notifications on your computer and mobile phone. This will help you to focus.",
    extraInformation:
      "Did you know- multi-tasking (reading email while doing other mental tasks) is equivalent to reducing IQ by 10 points. Lavie, N. (2005).",
    linkForPDF:
      "Lavie, N. (2005). Distracted and confused?: Selective attention under load. Trends in cognitive sciences, 9(2), 75-82.  ALSO FOCUS BY DANIEL GOLEMAN.",
    TED: "https://www.ted.com/talks/dallas_taylor_what_silence_can_teach_you_about_sound?language=en",
  },
  {
    title: "The Healing Power of Nature",
    text: "Go out to a nearby nature – the appearance of open spaces initiates a chain of chemical activity in the brain, the results of which include calm, serenity, and mental well-being.",
    extraInformation:
      "Research has found that participants who did five minutes of concentrated work and then looked at a nature picture for 40 seconds, showed a +14% improvement in concentration and performance vs a control group.",
    linkForPDF:
      "Selhub, E. M., & Logan, A. C. (2012). Your brain on nature: The science of nature's influence on your health, happiness and vitality. John Wiley & Sons.",
    TED: "https://www.youtube.com/watch?v=0uk0QriYYws",
  },
  {
    title: "Fake It Till You Become It",
    text: "Positive doing vs just positive thinking- Smile, breathe, feel proud; even if you are just pretending, your brain perceives it as real releasing feel-good hormones.",
    extraInformation:
      "“I don’t sing because I am happy, I am happy because I sing” / Williams James",
    linkForPDF:
      "Wiseman, R. (2014). The as if principle: The radically new approach to changing your life. Simon and Schuster.",
    TED: "https://www.youtube.com/watch?v=RVmMeMcGc0Y",
  },
  {
    title: "Mindfulness",
    text: "Be aware of what you're sensing and feeling in the moment, without interpretation or judgment. A few mindful moments can serve as an anchor in the midst of the storm.",
    extraInformation:
      "Test group engaged in mindfulness showed more activity in the frontal cortex associated with a happier, more focused, and more disease-immune brain (eight weeks for as little as 10 min a day). Davidson& Kabat-Zinn (2003).",
    linkForPDF:
      "Davidson, R. J., Kabat-Zinn, J., Schumacher, J., Rosenkranz, M., Muller, D., Santorelli, S. F., ... & Sheridan, J. F. (2003). Alterations in brain and immune function produced by mindfulness meditation. Psychosomatic medicine, 65(4), 564-570.",
    TED: "https://www.ted.com/talks/andy_puddicombe_all_it_takes_is_10_mindful_minutes?language=en",
  },
  {
    title: "Slow Down",
    text: "Reduce your pace to achieve better results. Slow down, look at the subtlety and check what new opportunities reveal to you at the new pace.",
    extraInformation:
      '"Life is too short to be in a hurry" / Henry David Thoreau.',
    linkForPDF:
      "Honoré, C. (2009). In praise of slow: How a worldwide movement is challenging the cult of speed. Vintage Canada.",
    TED: "https://www.ted.com/talks/carl_honore_in_praise_of_slowness?language=en",
  },
];
