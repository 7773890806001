import { IUser } from "@/lib/types";
import React from "react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";
import { Home, LogOut, User } from "lucide-react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Session from "supertokens-web-js/recipe/session";

export default function Header({
  user,
  children,
}: {
  user?: IUser;
  children: React.ReactNode;
}) {
  const [loggingOut, setLoggingOut] = useState(false);

  const navigate = useNavigate();

  async function logout() {
    setLoggingOut(true);
    await Session.signOut();
    setLoggingOut(false);
    navigate("/login", { replace: true });
  }

  return (
    <div>
      <div className="absolute w-screen top-0 left-0 px-8 pt-8 flex justify-between items-center">
        <Link to={"/"}>
          <h1 className="scroll-m-20 text-3xl sm:text-4xl font-extrabold tracking-tight lg:text-5xl cursor-pointer">
            Uppiness
          </h1>
        </Link>
        {user && (
          <div>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button
                  variant="outline"
                  className="font-bold text-lg"
                  disabled={loggingOut}
                >{`${user.firstName} ${user.lastName}`}</Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="w-56">
                <DropdownMenuGroup>
                  <Link to={"/"}>
                    <DropdownMenuItem>
                      <Home className="mr-2 h-4 w-4" />
                      <span>Home</span>
                    </DropdownMenuItem>
                  </Link>
                  <Link to={"/profile"}>
                    <DropdownMenuItem>
                      <User className="mr-2 h-4 w-4" />
                      <span>Profile</span>
                    </DropdownMenuItem>
                  </Link>
                </DropdownMenuGroup>
                <DropdownMenuSeparator />
                <DropdownMenuItem onClick={logout}>
                  <LogOut className="mr-2 h-4 w-4" />
                  <span>Log out</span>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        )}
      </div>
      {children}
    </div>
  );
}
