import {
  useCallStateHooks,
  ParticipantView,
  useParticipantViewContext,
} from "@stream-io/video-react-sdk";
import { Avatar, AvatarFallback } from "@/components/ui/avatar";
import { isMobile } from "react-device-detect";
import { IUser } from "@/lib/types";

export const VideoUI = ({ inLobby, user }: { inLobby: any; user?: IUser }) => {
  const { useParticipants, useDominantSpeaker } = useCallStateHooks();
  const participants = useParticipants();
  const dominantSpeaker = useDominantSpeaker();

  const CustomVideoPlaceholder = ({ style }: any) => {
    const { participant } = useParticipantViewContext();
    const currentPlayer = inLobby.players.find(
      (player: any) => player.supertokensId === participant.userId
    );
    const initials = currentPlayer?.firstName[0] + currentPlayer?.lastName[0];
    return (
      <div className="video-placeholder" style={style}>
        <Avatar className="w-8 h-8 sm:w-14 sm:h-14">
          <AvatarFallback className="bg-slate-500 text-white font-normal sm:font-extrabold tracking-tight text-xs sm:text-lg">
            {initials}
          </AvatarFallback>
        </Avatar>
      </div>
    );
  };

  const filteredParticipants = participants
    .filter((participant) =>
      inLobby.players.some(
        (player: any) => player.supertokensId === participant.userId
      )
    )
    .map((participant) => {
      const isCurrentUser = participant.userId === user?.supertokensId;
      const displayName = isCurrentUser
        ? `${user?.firstName} ${user?.lastName} (You)`
        : `${user?.firstName} ${user?.lastName}`;
      return {
        ...participant,
        name: displayName,
      };
    });

  //if dominant speaker is current user add (You) to their name
  if (dominantSpeaker) {
    const currentPlayer = user?.supertokensId === dominantSpeaker.userId;
    if (currentPlayer) {
      const displayName = `${user?.firstName} ${user?.lastName} (You)`;
      dominantSpeaker.name = displayName;
    }
  }

  return (
    <>
      <div className="fixed bottom-0 left-0 w-screen p-0 h-[50vh] max-h-[50vh] sm:h-[30vh] sm:h-[30vh] overflow-hidden">
        <div className="flex flex-col justify-end w-full h-full max-h-[50vh] sm:max-h-[30vh]">
          {!isMobile ? (
            <div className="flex flex-row justify-center h-full w-screen max-h-[30vh]">
              {filteredParticipants.map((p) => (
                <div
                  key={p.sessionId}
                  className={`w-full h-full text-xs sm:text-base font-light sm:font-extrabold bg-slate-800`}
                >
                  <ParticipantView
                    participant={p}
                    VideoPlaceholder={CustomVideoPlaceholder}
                  />
                </div>
              ))}
            </div>
          ) : (
            <div>
              {filteredParticipants.length > 2 ? (
                <div>
                  <div>
                    {dominantSpeaker && (
                      <div>
                        <div
                          className={`w-full text-xs sm:text-base font-light sm:font-extrabold bg-slate-800`}
                        >
                          <ParticipantView
                            participant={dominantSpeaker}
                            VideoPlaceholder={CustomVideoPlaceholder}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="flex flex-row justify-center">
                    {filteredParticipants
                      .filter((p) => p.userId !== dominantSpeaker?.userId)
                      .map((p) => (
                        <div
                          key={p.sessionId}
                          className={`w-full text-xs sm:text-base font-light sm:font-extrabold bg-slate-800`}
                        >
                          <ParticipantView
                            participant={p}
                            VideoPlaceholder={CustomVideoPlaceholder}
                          />
                        </div>
                      ))}
                  </div>
                </div>
              ) : (
                <div className="h-full max-h-[50vh]">
                  {filteredParticipants.map((p) => (
                    <div
                      key={p.sessionId}
                      className={`w-full ${
                        filteredParticipants.length === 1
                          ? "h-full"
                          : filteredParticipants.length === 2
                          ? "h-1/2"
                          : filteredParticipants.length === 3
                          ? "h-1/3"
                          : "h-1/4"
                      } sm:h-full text-xs sm:text-base font-light sm:font-extrabold bg-slate-800`}
                    >
                      <ParticipantView
                        participant={p}
                        VideoPlaceholder={CustomVideoPlaceholder}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
