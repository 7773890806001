import { Loader2 } from "lucide-react";
import React, { ReactElement, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Session from "supertokens-web-js/recipe/session";

export default function RequireAuth({ children }: { children: ReactElement }) {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<null | string>(null);

  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    async function doesSessionExist() {
      try {
        if (await Session.doesSessionExist()) {
          const userResponse = await fetch(
            `${process.env.REACT_APP_API_URL}/myProfile`
          );
          const userData = await userResponse.json();

          setUser(userData);
        } else {
          navigate("/login", { replace: true });
        }
        setLoading(false);
      } catch (e) {
        console.log(e);
      }
    }
    doesSessionExist();
    //eslint-disable-next-line
  }, []);

  return (
    <>
      {loading ? (
        <div className="h-screen flex justify-center items-center">
          <Loader2 className="h-16 w-16 animate-spin" />
        </div>
      ) : (
        React.cloneElement(children, { user })
      )}
    </>
  );
}
