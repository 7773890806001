import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { Loader2, Users, X } from "lucide-react";
import { Avatar, AvatarFallback } from "@/components/ui/avatar";
import { IUser } from "@/lib/types";

export default function GameLobby({
  leaveLobby,
  inLobby,
  user,
  startMatch,
}: {
  leaveLobby: any;
  inLobby: any;
  user?: IUser;
  startMatch: any;
}) {
  return (
    <div>
      <div className="flex justify-center mb-8">
        <Button
          className="text-sm"
          variant={"destructive"}
          onClick={() => leaveLobby(inLobby.id)}
        >
          <X className="mr-2 h-4 w-4" />
          Leave lobby
        </Button>
      </div>
      <div className="flex justify-center">
        <Button
          variant={"outline"}
          className="font-bold flex justify-between items-center text-xl sm:text-4xl p-8"
        >
          {inLobby.name}'s lobby{" "}
          <Badge className="ml-3">
            <Users className="w-4 h-4 mr-2" />
            {inLobby.players.length}/{process.env.REACT_APP_MAX_PLAYERS}
          </Badge>
        </Button>
      </div>
      <div className="mt-24 flex justify-center">
        <div>
          {inLobby.players.map((player: any) => (
            <div key={player.supertokensId} className="mb-8">
              <Button className="w-80 sm:w-96 justify-start font-bold bg-lime-500">
                <Avatar className="w-14 h-14 mr-4">
                  <AvatarFallback className="bg-black text-white font-extrabold tracking-tight text-xl">
                    {player.firstName[0]}
                    {player.lastName[0]}
                  </AvatarFallback>
                </Avatar>
                {player.firstName} {player.lastName}
              </Button>
            </div>
          ))}
          {inLobby.status === "open" &&
            Array.from(
              {
                length:
                  Number(process.env.REACT_APP_MAX_PLAYERS) -
                  inLobby.players.length,
              },
              (_, index) => (
                <div key={`waiting-${index}`} className="mb-8">
                  <Button
                    variant={"secondary"}
                    className="w-80 sm:w-96 justify-start border-outline"
                  >
                    <Avatar className="w-14 h-14 mr-4">
                      <AvatarFallback className="bg-black text-white font-extrabold tracking-tight text-xl">
                        <Loader2 className="h-6 w-6 animate-spin" />
                      </AvatarFallback>
                    </Avatar>
                    Waiting for players...
                  </Button>
                </div>
              )
            )}
          {inLobby.status === "done" && (
            <div className="mt-28 sm:mt-36">
              <h1 className="tracking-tight font-extrabold text-center text-3xl">
                Game has ended!
              </h1>
            </div>
          )}
          {inLobby.creator.supertokensId === user?.supertokensId &&
            inLobby.players.length >= 2 &&
            inLobby.status === "open" && (
              <div className="fixed w-full bottom-0 left-0 p-4 sm:p-8">
                <Button
                  className="w-full text-3xl sm:text-5xl p-8 sm:p-12 font-extrabold tracking-tight"
                  onClick={() => startMatch(inLobby.id)}
                >
                  Start Game
                </Button>
              </div>
            )}
        </div>
      </div>
    </div>
  );
}
