import { IUser } from "@/lib/types";
import Header from "@/components/header";
import { Avatar, AvatarFallback } from "@/components/ui/avatar";

export default function Profile({ user }: { user?: IUser }) {
  return (
    <Header user={user}>
      <div className="h-screen flex justify-center items-center">
        <div>
          <div className="flex items-center">
            <Avatar className="w-24 sm:w-28 h-24 sm:h-28">
              <AvatarFallback className="bg-white text-black font-extrabold tracking-tight text-3xl">
                {user?.firstName[0]}
                {user?.lastName[0]}
              </AvatarFallback>
            </Avatar>
            <div className="ml-8 sm:ml-10">
              <h1 className="tracking-tight text-2xl sm:text-3xl">
                {user?.firstName} {user?.lastName}
              </h1>
              <h1 className="tracking-tight text-2xl sm:text-3xl mt-4">
                {user?.email}
              </h1>
            </div>
          </div>
        </div>
      </div>
    </Header>
  );
}
