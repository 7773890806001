import { Badge } from "@/components/ui/badge";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { Button } from "@/components//ui/button";
import { Gamepad2, Users } from "lucide-react";

export default function HomeLobby({
  createLobby,
  clientsCount,
  joinLobby,
  lobbies,
}: {
  createLobby: () => void;
  clientsCount: number | null;
  joinLobby: (lobbyId: string) => void;
  lobbies: any;
}) {
  return (
    <>
      <div className="flex justify-center">
        <Button className="text-xl font-extrabold" onClick={createLobby}>
          <Gamepad2 className="mr-3 h-8 w-8" /> Start a new game
        </Button>
      </div>
      <div
        className={`mt-4 flex items-center justify-center ${
          clientsCount !== null ? "opacity-100" : "opacity-0"
        }`}
      >
        <div className="w-2 h-2 rounded-full bg-lime-400" />
        <div className="font-bold text-sm ml-2">
          {clientsCount} player{clientsCount === 1 ? "" : "s"} online
        </div>
      </div>
      <div className="mt-24 flex justify-center">
        <div>
          {lobbies &&
            Object.keys(lobbies)
              .filter((lobbyId: any) => lobbies[lobbyId].status === "open")
              .map((lobbyId) => (
                <div
                  key={lobbyId}
                  onClick={() => joinLobby(lobbyId)}
                  className="mb-4 flex"
                >
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button
                        variant={"outline"}
                        className="font-bold w-80 sm:w-96 flex justify-between items-center"
                      >
                        {lobbies[lobbyId].name}'s lobby{" "}
                        <Badge className="ml-3">
                          <Users className="w-4 h-4 mr-2" />
                          {lobbies[lobbyId].players.length}/
                          {process.env.REACT_APP_MAX_PLAYERS}
                        </Badge>
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent
                      className="cursor-pointer"
                      onClick={() => joinLobby(lobbyId)}
                    >
                      <p className="font-bold">JOIN LOBBY</p>
                    </TooltipContent>
                  </Tooltip>
                </div>
              ))}
        </div>
      </div>
    </>
  );
}
