import { useEffect, useRef, useState } from "react";
import {
  StreamCall,
  StreamVideo,
  StreamVideoClient,
  User,
} from "@stream-io/video-react-sdk";
import { VideoUI } from "@/components/video-ui";
import { IUser } from "@/lib/types";
import SelectStrengths from "@/components/select-strengths";
import SelectDilemma from "./select-dilemma";
import LeaveGame from "./leave-game";

export default function GameInProgress({
  user,
  inLobby,
  leaveLobby,
  selectStrengths,
  selectDilemma,
  selectSolution,
  chooseSolution,
}: {
  user?: IUser;
  inLobby: any;
  leaveLobby: any;
  selectStrengths: any;
  selectDilemma: any;
  selectSolution: any;
  chooseSolution: any;
}) {
  const [client, setClient] = useState<StreamVideoClient | null>(null);
  const [call, setCall] = useState<any>(null);
  const effectRan = useRef(false);

  useEffect(() => {
    if (user && inLobby && !client && !call) {
      const streamApiKey = process.env.REACT_APP_STREAM_API_KEY || "";
      const streamUserId = user?.supertokensId || "";
      const streamCallId = inLobby.id;

      const streamUser: User = { id: streamUserId };

      const newClient = new StreamVideoClient({
        apiKey: streamApiKey,
        user: streamUser,
        token: user?.streamToken || "",
      });

      const newCall = newClient.call("default", streamCallId);

      setClient(newClient);
      setCall(newCall);
    }
  }, [user, inLobby, client, call]);

  useEffect(() => {
    if (effectRan.current === false && call) {
      const joinCall = async () => {
        try {
          await call.join();
          console.log("joined call");
        } catch (e) {
          console.error(e);
        }
      };

      joinCall();

      effectRan.current = true;
    }
  }, [call]);

  if (!client || !call) return null;

  return (
    <div>
      <StreamVideo client={client}>
        <StreamCall call={call}>
          <div className="fixed top-0 left-0 h-[50vh] sm:h-[70vh] bg-black w-screen">
            <LeaveGame leaveLobby={leaveLobby} inLobby={inLobby} />
            {inLobby.phase === "strengthSelection" ? (
              <SelectStrengths
                selectStrengths={selectStrengths}
                inLobby={inLobby}
                user={user}
              />
            ) : inLobby.phase === "dilemmaSelection" ? (
              <SelectDilemma inLobby={inLobby} user={user} />
            ) : (
              <div>Hello</div>
            )}
          </div>
          <VideoUI inLobby={inLobby} user={user} />
        </StreamCall>
      </StreamVideo>
    </div>
  );
}
