import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useState, useEffect } from "react";
import { signUp } from "supertokens-web-js/recipe/emailpassword";
import { toast } from "sonner";
import { Loader2 } from "lucide-react";
import { Link, useNavigate } from "react-router-dom";
import Session from "supertokens-web-js/recipe/session";
import Header from "@/components/header";

export function SignUpForm() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    async function doesSessionExist() {
      if (await Session.doesSessionExist()) {
        navigate("/", { replace: true });
      }
    }
    doesSessionExist();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setEmail("");
    setPassword("");
    setFirstName("");
    setLastName("");
  }, []);

  async function signUpClicked({
    email,
    password,
    firstName,
    lastName,
  }: {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
  }) {
    setLoading(true);
    try {
      let response = await signUp({
        formFields: [
          {
            id: "email",
            value: email,
          },
          {
            id: "password",
            value: password,
          },
          { id: "firstName", value: firstName },
          { id: "lastName", value: lastName },
        ],
      });

      if (response.status === "FIELD_ERROR") {
        // one of the input formFields failed validaiton
        response.formFields.forEach((formField) => {
          if (formField.id === "email") {
            // Email validation failed (for example incorrect email syntax),
            // or the email is not unique.
            toast.error(`Email: ${formField.error}`);
          } else if (formField.id === "password") {
            // Password validation failed.
            // Maybe it didn't match the password strength
            toast.error(`Password: ${formField.error}`);
          } else if (formField.id === "firstName") {
            // First name validation failed.
            toast.error(`First name: ${formField.error}`);
          }
          // lastName is not unique
          else if (formField.id === "lastName") {
            toast.error(`Last name: ${formField.error}`);
          }
        });
      } else if (response.status === "SIGN_UP_NOT_ALLOWED") {
        // the reason string is a user friendly message
        // about what went wrong. It can also contain a support code which users
        // can tell you so you know why their sign up was not allowed.
        toast.error(response.reason);
      } else {
        // sign up successful. The session tokens are automatically handled by
        // the frontend SDK.
        // window.location.href = "/homepage";
        toast.success("Sign up successful");
        navigate("/", { replace: true });
      }
    } catch (err: any) {
      if (err.isSuperTokensGeneralError === true) {
        // this may be a custom error message sent from the API by you.
        toast.error(err.message);
      } else {
        toast.error("Oops! Something went wrong.");
      }
    }
    setLoading(false);
  }

  return (
    <Header>
      <div className="h-screen flex justify-center items-center">
        <Card className="mx-auto max-w-sm">
          <CardHeader>
            <CardTitle className="text-xl">Sign Up</CardTitle>
            <CardDescription>
              <span className="opacity-0">
                Enter your information to create an account
              </span>
            </CardDescription>
          </CardHeader>
          <CardContent>
            <div className="grid gap-4">
              <div className="grid grid-cols-2 gap-4">
                <div className="grid gap-2">
                  <Label htmlFor="first-name">First name</Label>
                  <Input
                    id="first-name"
                    required
                    onChange={(e) => setFirstName(e.target.value)}
                    className="text-base"
                  />
                </div>
                <div className="grid gap-2">
                  <Label htmlFor="last-name">Last name</Label>
                  <Input
                    id="last-name"
                    required
                    onChange={(e) => setLastName(e.target.value)}
                    className="text-base"
                  />
                </div>
              </div>
              <div className="grid gap-2">
                <Label htmlFor="email">Email</Label>
                <Input
                  id="email"
                  type="email"
                  required
                  onChange={(e) => setEmail(e.target.value)}
                  className="text-base"
                />
              </div>
              <div className="grid gap-2">
                <Label htmlFor="password">Password</Label>
                <Input
                  id="password"
                  type="password"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      signUpClicked({ email, password, firstName, lastName });
                    }
                  }}
                  onChange={(e) => setPassword(e.target.value)}
                  className="text-base"
                />
              </div>
              <Button
                type="submit"
                className="w-full"
                onClick={() =>
                  signUpClicked({ email, password, firstName, lastName })
                }
                disabled={loading}
              >
                {loading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
                Create an account
              </Button>
              {/*<Button variant="outline" className="w-full">
            Sign up with GitHub
  </Button>*/}
            </div>
            <div className="mt-4 text-center text-sm">
              Already have an account?{" "}
              <Link to="/login" className="underline cursor-pointer">
                Sign in
              </Link>
            </div>
          </CardContent>
        </Card>
      </div>
    </Header>
  );
}
